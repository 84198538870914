import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo"
import PageHeader from "../components/Page/PageHeader"
import ArticlesGrid from "../components/News/ArticlesGrid"
import ArticleSearch from "../components/News/ArticleSearch"

const NewsIndex = ({ data }) => {
  const {
    prismicNews: { data: newsData },
  } = data

  const {
    meta_title,
    meta_description,
    subheading,
    header_image,
    enable_shadow,
    header_title,
    featured_articles: articles,
  } = newsData

  const featuredArticles = articles.map(
    article => article.featured_article.document
  )

  return (
    <Layout>
      <Seo title={meta_title || "News"} description={meta_description} />
      <PageHeader
        title={header_title}
        subheading={subheading}
        image={header_image}
        shadow={enable_shadow}
      />
      <div className="mx-auto mt-24 max-w-6xl px-gutter">
        <h2 className="text-4xl font-bold uppercase">Featured Articles</h2>
        <ArticlesGrid articles={featuredArticles} />
        <ArticleSearch />
      </div>
    </Layout>
  )
}

export default NewsIndex

export const query = graphql`
  query NewsQuery {
    prismicNews {
      data {
        meta_title
        meta_description
        header_image {
          gatsbyImageData
          alt
        }
        subheading
        header_title
        enable_shadow
        featured_articles {
          featured_article {
            document {
              ... on PrismicArticle {
                id
                url
                last_publication_date(formatString: "MMMM DD, YYYY")
                data {
                  title
                  subheading
                  intro
                  read_time
                  category {
                    document {
                      ... on PrismicArticleCategory {
                        id
                        data {
                          category
                        }
                      }
                    }
                  }
                  card_image {
                    gatsbyImageData
                    alt
                  }
                  date(formatString: "MMMM DD, YYYY")
                }
              }
            }
          }
        }
      }
    }
  }
`
