import React, { useState, useMemo, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import ArticleFilter from "./ArticleFilter"
import useArticles from "../../hooks/useArticles"
import Pagination from "./Pagination"
import ArticlesGrid from "./ArticlesGrid"
import { useQueryParamString } from "react-use-query-param-string"

const ArticleSearch = () => {
  const data = useStaticQuery(graphql`
    query AllArticlesAndCategoriesQuery {
      allPrismicArticle(sort: { fields: data___date, order: DESC }) {
        nodes {
          id
          url
          tags
          last_publication_date(formatString: "MMMM DD, YYYY")
          data {
            title
            read_time
            category {
              document {
                ... on PrismicArticleCategory {
                  id
                  data {
                    category
                  }
                }
              }
            }
            card_image {
              gatsbyImageData
              alt
            }
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  `)

  const allArticles = data.allPrismicArticle.nodes

  const categories = useMemo(() => {
    return Array.from(
      new Set(
        allArticles
          .map(article => article.data.category?.document?.data?.category)
          .filter(Boolean)
      )
    )
  }, [allArticles])

  const [selectedCategory, setSelectedCategory] = useQueryParamString(
    "category",
    ""
  )
  const [searchTerm, setSearchTerm] = useQueryParamString("search", "")
  const [currentPage, setCurrentPage] = useQueryParamString("page", "1")
  const articlesPerPage = 6

  const { articles, numPages, loading, error } = useArticles(
    allArticles,
    selectedCategory,
    searchTerm,
    currentPage,
    articlesPerPage
  )

  const handleCategoryChange = category => {
    setSelectedCategory(category)
    setCurrentPage(1)
  }

  const handleSearchChange = term => {
    setSearchTerm(term)
    setCurrentPage(1)
  }

  const headerRef = useRef(null)

  const handlePaginationClick = page => {
    setCurrentPage(page)
    headerRef.current.scrollIntoView()
  }

  return (
    <div ref={headerRef} className="my-32">
      <h2 className="text-4xl font-bold uppercase">Latest Articles</h2>
      <ArticleFilter
        categories={categories}
        selectedCategory={selectedCategory}
        onCategoryChange={handleCategoryChange}
        onSearchChange={handleSearchChange}
        searchTerm={searchTerm}
      />
      {loading ? (
        <div className="mt-12 text-center">Loading...</div>
      ) : error ? (
        <div className="mt-12 text-center">Error loading articles</div>
      ) : articles.length === 0 ? (
        <div className="mt-12 text-center">No articles found</div>
      ) : (
        <ArticlesGrid articles={articles} />
      )}
      <Pagination
        numPages={numPages}
        currentPage={currentPage}
        handlePaginationClick={handlePaginationClick}
      />
    </div>
  )
}

export default ArticleSearch
