import * as React from "react"
import SearchIcon from "../SVG/Search"

const ArticleFilter = ({
  categories,
  selectedCategory,
  onCategoryChange,
  onSearchChange,
  searchTerm,
}) => {
  return (
    <div className="mt-12 grid items-baseline gap-x-8 md:grid-cols-3">
      <div className="pt-8 md:pt-0">
        <label className="flex w-full flex-col border-b font-bold uppercase md:w-4/5">
          Category
          <select
            className="pt-1 focus:outline-none"
            value={selectedCategory}
            onChange={e => onCategoryChange(e.target.value)}
          >
            <option value="All" defaultValue="All">
              All
            </option>
            {Array.from(new Set(categories)).map(category => (
              <option key={category}>{category}</option>
            ))}
          </select>
        </label>
      </div>

      <div className="mt-auto flex w-full justify-between pt-8 md:pt-0">
        <input
          className="w-full border-b focus:outline-none md:w-4/5"
          placeholder="Search..."
          label="Search"
          type="search"
          value={searchTerm}
          onChange={e => onSearchChange(e.target.value)}
        />
        <div className="h-5 w-5">
          <SearchIcon />
        </div>
      </div>
    </div>
  )
}

export default ArticleFilter
