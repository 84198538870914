import React from "react"

const Pagination = ({ numPages, currentPage, handlePaginationClick }) => {
  const pages = []
  for (let i = 1; i <= numPages; i++) {
    pages.push(i)
  }

  return (
    <div className="mt-20 flex justify-center">
      {pages.map(page => (
        <button
          key={page}
          onClick={() => handlePaginationClick(page)}
          className={`pagination-button ${
            parseInt(currentPage) === page
              ? "text-lg font-semibold text-coral-pink"
              : "text-white py-2 px-4 text-lg font-semibold"
          }`}
        >
          {page}
        </button>
      ))}
    </div>
  )
}

export default Pagination
