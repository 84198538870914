import React from "react"

const SearchIcon = () => {
  return (
    <svg className="h-full w-full" fill="none" viewBox="0 0 18 19">
      <path d="m13 14 4.331 4.331" stroke="#000" />
      <circle cx="8" cy="8" r="7.5" stroke="#000" />
    </svg>
  )
}

export default SearchIcon
