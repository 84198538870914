import { useState, useEffect } from "react"

const useArticles = (
  allArticles,
  category,
  searchTerm,
  page,
  articlesPerPage
) => {
  const [articles, setArticles] = useState([])
  const [numPages, setNumPages] = useState(1)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    setError(null)
    try {
      setLoading(true)
      let filteredArticles = allArticles

      if (category && category !== "All") {
        filteredArticles = filteredArticles.filter(
          article =>
            article.data.category?.document?.data?.category === category
        )
      }

      if (searchTerm) {
        const escapedSearchTerm = searchTerm.replace(
          /[.*+?^${}()|[\]\\]/g,
          "\\$&"
        )
        const searchRegex = new RegExp(escapedSearchTerm, "i")
        filteredArticles = filteredArticles.filter(
          article =>
            searchRegex.test(article.data.title) ||
            article.tags.some(tag => searchRegex.test(tag))
        )
      }

      setNumPages(Math.ceil(filteredArticles.length / articlesPerPage))
      const start = (page - 1) * articlesPerPage
      const end = start + articlesPerPage
      setArticles(filteredArticles.slice(start, end))
    } catch (error) {
      setError(error.toString())
    } finally {
      setLoading(false)
    }
  }, [allArticles, category, searchTerm, page, articlesPerPage])

  return { articles, numPages, loading, error }
}

export default useArticles
